import React from "react"
import axios from 'axios';

import { useState , useEffect} from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';

import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';


const Domain= process.env.REACT_APP_DOMAIN

function ClientForm(props)  {

  const {created} = props

  const [name ,setname] = useState('');
  const [phone_id,setPhoneId] = useState('');
  const [account_id,setAccount] = useState('');
  const [phone_number ,setPhone] = useState('');
  const [default_flag ,setDeafult] = useState(false);


  const handleSubmit = async (e) =>  {
    e.preventDefault()
 
    const formData = new FormData()

    formData.append('name', name);
    formData.append('phone_id', phone_id);
    formData.append('account_id',account_id);
    formData.append('phone_number', phone_number.toString());
    formData.append('default', default_flag);

    const config = {
        headers: {
            'Content-Type': 'application/json',  
        }
    }
    try {       
        await axios.post(Domain+'/api/phones',formData, config)
        .then(function(res){
           
            if (res=={}){
              created(false); 
 

            }else{
              created(true); 
              
            }
                 
        })
      } catch (err) {
        created(false); 
        console.log(err);
      }

};


    useEffect(() => {

     
  } , []);   


   
    return (
      
      <Box
        component="form"
        sx={{
          width: 500,
          height: 400,
          backgroundColor: '#eceff1',
          
        }}
        noValidate
        autoComplete="off"
      >

      <Stack direction="column" alignItems="center" spacing={1} sx={{p:3}}>

      <FormControl>
        <FormHelperText id="nombre">Nombre</FormHelperText>
        <Input id="nombre" aria-describedby="Nombre" fullWidth onChange={event => setname(event.target.value)}
        sx={{
        width: { sm: 200, md: 300 },
        "& .MuiInputBase-root": {
            height: 80
        }
    }}/>
      </FormControl>
     
      <FormControl>
        <FormHelperText id="phone_id">Id del teléfono (desde Wapp)</FormHelperText>
        <Input id="phone_id" aria-describedby="phone_id" fullWidth onChange={event => setPhoneId(event.target.value)}
        sx={{
          width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 80
          }
      }}/>
      </FormControl>

      <FormControl>
        <FormHelperText id="account_id">Id de la cuenta (desde Wapp)</FormHelperText>
        <Input id="account_id" aria-describedby="account_id" fullWidth onChange={event => setAccount(event.target.value)}
        sx={{
          width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 80
          }
      }}/>
      </FormControl>
      <FormControl>
        <FormHelperText id="Telefono">Teléfono</FormHelperText>
        <Input id="Teléfono" aria-describedby="Teléfono" fullWidth onChange={event => setPhone(event.target.value)}
        sx={{
          width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 80
          }
      }}/>
      </FormControl>

      <FormControl>
        <FormHelperText id="Email">Elegir por defecto</FormHelperText>


        <Checkbox checked={default_flag} size="small" id="default" aria-describedby="Default" onChange={event => setDeafult(event.target.checked)}
        sx={{
          width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 80
          }
      }}></Checkbox>

       
      </FormControl>
    
    

      <Box justifyContent="flex-end" alignItems="flex-end"   display="flex" 
     sx={{
          p:3
      }} 
      >
        <Button  variant="outlined" size="small" onClick={e=>handleSubmit(e)}>
          Crear
        </Button>
      </Box>

      </Stack>

    </Box>


    )
};

export default ClientForm;

       