// eslint-disable-next-line
import logo from '../logo.svg';
import '../App.css';
import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Drawer from './NavDrawer';
import axios from 'axios';
import Box from '@mui/material/Box';
import Navbar from './Navbar';

import { useNavigate } from "react-router-dom";

const Domain= process.env.REACT_APP_DOMAIN




function Dashboard () {

    const navigate = useNavigate();
    const view='Dashboard';
    const [authenticated, setauthenticated] = useState(false)
    const [clients, setClients] = useState({});
    const [phones, setphones] = useState({});

    useEffect(() => {

      const getClients = async ()=> {
            try {      
                 await axios.get(Domain+'/api/clients')
                .then(function(response){
                    setClients(response.data);
                    localStorage.setItem("clients", JSON.stringify(response.data));

                })
          } catch (err) {

            console.log("error get clientes");
          } 
        };

        const getContracts = async ()=> {
            try {       
              await axios.get(Domain+'/api/contracts')
                .then(function(response){
                    setClients(response.data);
                    localStorage.setItem("contracts", JSON.stringify(response.data));
                })
          } catch (err) {

            console.log("error get contracts");
          } 
        };

        const getwappTemplates = async ()=> {
          try {       
            await axios.get(Domain+'/api/wapp/templates')
              .then(function(response){
                localStorage.setItem("templates", JSON.stringify(response.data.data.message_templates));

              })
        } catch (err) {

          console.log(err);
        } 
      };

        const getStats = async () =>{
          try {       
            await axios.post(Domain+'/api/stats')
            .then(function(response){
                    localStorage.setItem("stats", JSON.stringify(response.data));
                })
          } catch (err) {

            console.log("error get stats");
          }  
        };

        const checkAuth =()=> {
            if (!JSON.parse(sessionStorage.getItem("authenticated"))) 
            {
                navigate('../', { replace: true });
            } 
        };

        const getphones = async () =>{
          try {      
            await axios.get(Domain+'/api/phones')
            .then(function(response){
                setphones(response.data);
                localStorage.setItem("phones", JSON.stringify(response.data));
    
            })
          } catch (err) {
    
            console.log("error get phones");
          } 
        
        }
        checkAuth();
        getClients();
        getContracts();
        getStats();
        getwappTemplates();
        getphones();
    } , []);   

  
        
        return (
         
          <Box >
            <Navbar></Navbar>
            <Drawer view={view}></Drawer>
          </Box>
      );
   
  }

  export default Dashboard;