import '../../App.css';
import React, {useEffect, useState} from 'react';
import Drawer from '../NavDrawer';
import Box from '@mui/material/Box';
import Navbar from '../Navbar';

function Contracts(){
    const view='Template';

    return (
        
        <Box >
            <Navbar></Navbar>
            <Drawer view={view}></Drawer>
        </Box>
    );
}

export default Contracts