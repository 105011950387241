import '../../App.css';
import React, {useEffect, useState} from 'react';
import Drawer from '../NavDrawer';
import Box from '@mui/material/Box';
import Navbar from '../Navbar';

function Clients(){
    const view='Clients';
    const [clients, setClients] = useState(localStorage.getItem("clients"));

    return ( 
        <Box sx={{ flexGrow: 1 }}>
            <Navbar></Navbar>
            <Drawer view={view}></Drawer>
        </Box>
    );
}

export default Clients