import '../../App.css';
import React, {useEffect, useState} from 'react';
import Drawer from '../NavDrawer';
import Box from '@mui/material/Box';
import Navbar from '../Navbar';
import axios from 'axios';

const Domain= process.env.REACT_APP_DOMAIN

function Config(){
    const view='Config';
   
    return (


        <Box sx={{ flexGrow: 1 }}>
            <Navbar></Navbar>
            <Drawer view={view}></Drawer>
        </Box>
    );
}

export default Config