import React from "react"
import axios from 'axios';

import { useState , useEffect} from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ConfigForm from './Config_form';


import CheckIcon from '@mui/icons-material/Check';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Domain= process.env.REACT_APP_DOMAIN

const columns = [
    {id: 'id', label:'id', minWidth:100},
    { id: 'name', label: 'Nombre', minWidth: 170 },
    { id: 'phone_id', label: 'ID Teléfono', minWidth: 100 },
    { id: 'account_id', label: 'ID de la cuenta', minWidth: 100 },
    { id: 'phone', label: 'Teléfono', minWidth: 100 },
    {
      id: 'default',
      label: 'Default',
      minWidth: 170,
      align: 'right',
    },

  ];
  
  
const rows =[];

function Config_list ()  {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [phones, setphones] = useState(JSON.parse(localStorage.getItem("phones")));
    const [rows ,setRows] = useState([]);
    const [showNew, setShow] = useState(false);
    const [selectedrow, setSelectedRow] = useState();


    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const handleShow = (event) => {
      setShow(!showNew)
    
    }

    const handleSelect = (id)=>{
      console.log(id)
      setSelectedRow(id);
    }

    const updatePhones = async () =>{
      try {      
        await axios.get(Domain+'/api/phones')
        .then(function(response){
            setphones(response.data);
            console.log(response.data);
            localStorage.setItem("phones", JSON.stringify(response.data));

        })
      } catch (err) {

        console.log(err);
      } 
    
      window.location.reload(false);
    }


    const setCreated  = async (target)=>{    
      setShow(false);
      updatePhones();
     
    };

    const remove  = async ()=>{    

      try {      
        await axios.post(Domain+'/api/phones/delete/'+selectedrow)
        .then(function(response){
          console.log(response)
            if (response.data.message=="Success"){

              updatePhones();
 
            }
        })
      } catch (err) {

        console.log(err);
      } 
    
    };

    useEffect(() => {

      const createRows =()=> {
        const data=phones.data;
        const array =[];

        data.forEach(function(element) 
        {
            array.push([element.id,element.name,element.phone_id,element.account_id, element.phone_number,(element.default).toString()]);
        });
       
        setRows(array);
          
      };

      const updatePhones = async () =>{
        try {      
          await axios.get(Domain+'/api/phones')
          .then(function(response){
              setphones(response.data);
              console.log(response.data);
              localStorage.setItem("phones", JSON.stringify(response.data));
  
          })
        } catch (err) {
  
          console.log(err);
        } 
      
      };
      updatePhones();
      createRows();
  } , []);   


   
    return (

      <Box sx={{ flexGrow: 1}}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={11}>
          <Item  elevation={0}>

          {
              showNew==true
              ? <div>
                  <ConfigForm created={setCreated}></ConfigForm>
              </div>
              : <div>
                    <TableContainer sx={{ maxHeight: 440 }}>
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow >
                                    {columns.map((column) => (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                      >
                                        {column.label}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row,rowIndex) => {

                                      return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row[0]}  name={row[0]} 
                                        enableRowSelection
                                        onClick={() => handleSelect(row[0])}
                                        selected = {selectedrow== row[0]? true : false}
                                        >


                                          {columns.map((column, index) => {
                                            return (
                                              <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof row[index] === 'number'
                                                  ? column.format(row[index])
                                                  : row[index]}
                                              </TableCell>
                                            );
                                          })}
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <TablePagination
                              rowsPerPageOptions={[10, 25, 100]}
                              component="div"
                              count={rows.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
              </div>
 
          }
                   
          </Item>
        </Grid>
        <Grid item xs={1}>
          <Item elevation={0}>
          <Box >
          <Stack direction="column" alignItems="center" spacing={1}>
                <div>
                <IconButton  aria-label="Nuevo Teléfono" component="label" onClick={(event) => { handleShow(event) }}
                sx={{ 
                  color: "white", 
                  backgroundColor: "#e91e63", 
                  borderRadius: "50%" 
                }}
              
                >
                <AddIcon />
              </IconButton>
                </div>
                <div>
                <IconButton color="primary" aria-label="Borrar Teléfono " component="label" onClick={remove }
                sx={{ 
                  color: "white", 
                  backgroundColor: "#e91e63", 
                  borderRadius: "50%" 
                }}>
                <DeleteIcon />
              </IconButton>
                </div>

          </Stack>

            </Box>
          </Item>
        </Grid>
  

      </Grid>
    </Box>


    )
};

export default Config_list;

       