import React from "react"
import axios from 'axios';

import { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";

function Logout(){
    const navigate = useNavigate();


    useEffect(() => {
        
        sessionStorage.setItem("authenticated", JSON.stringify(false));  
        navigate('../', { replace: true });
    
    } , []);
    
    
}

export default Logout;