import React, { useState } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Grid from '@mui/material/Grid';


const Domain= process.env.REACT_APP_DOMAIN


function SendForm (){

    const [sent,setSent]=useState({});
    const [client,setClient]=useState("");
    const [mobile,setMobile]=useState("");
    const [template,setTemplate]=useState("");
    const [text,SetText]=useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [loadedFile, setLoadedFile]=useState(false);

    function onFileChange(event){
        setSelectedFile(event.target.files[0]);
        setLoadedFile(true)
    }

    function uploadAction(event){
        event.preventDefault();

        const formData = new FormData()
        formData.append('client', client);
        formData.append('mobile', mobile);
        formData.append('template', template);
        formData.append('text', text);
        formData.append('file',selectedFile)



        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',  
            }
        }
        try {         
            axios.post(Domain+'/wapp/send_wapp',formData,config)
            .then(function(response){
                setSent(response.data.message);
              }
            )
          } catch (err) {
            console.log(err);
          }
    }
    return(
        <Grid direction='row' container spacing={1}>
            <Col    style={{paddingLeft: '10px',paddingTop: '5px', paddingRight: '10px',paddingBottom: '1px',width: '30rem' }}>
                <Card >
                    <Card.Header>Carga de plantilla</Card.Header>
                        <Card.Body>
                        {sent === "OK" &&
                            <Alert variant="success">
                                ¡La solicitud de envío individual está en procesamiento!
                            </Alert>
                        }
                        
                            <Form onSubmit={uploadAction}>
                                <Form.Group className="mb-3">
                                <Form.Label>Cliente</Form.Label>
                                <Form.Control placeholder="Nombre del cliente" 
                                onChange={event => setClient(event.target.value)}
                                enabled />
                                </Form.Group>

                                {/* <Form.Group className="mb-3">
                                <Form.Label>Contacto (incluir indicativo del país)</Form.Label>
                                <Form.Select enabled>
                                    <option></option>
                                </Form.Select>
                                </Form.Group>  */}

                                <Form.Group className="mb-3">
                                <Form.Label>Contacto (incluir indicativo del país)</Form.Label>
                                <Form.Control placeholder="57311*******" enabled  
                                    // onChange={(e) =>this.props.test(e.target.value)}
                                    onChange={event => setMobile(event.target.value)}
                                />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                <Form.Label>Plantilla</Form.Label>
                                <Form.Control placeholder="Nombre de la plantilla WAPP" 
                                onChange={event => setTemplate(event.target.value)}
                                enabled />
                                </Form.Group>

                                
                                <Form.Group className="mb-3">
                                <Form.Label>Texto</Form.Label>
                                <Form.Control placeholder="Texto"
                                onChange={event => SetText(event.target.value)}
                                enabled as="textarea" rows={3}  />
                                </Form.Group>

            

                                <br></br>
                                <Form.Group className="mb-3">
                                <Form.Check type="checkbox" label="confirmar el envío" enabled />
                                </Form.Group>

                                <Button type="submit" onClick={uploadAction}>Enviar</Button>
                            </Form>
                            </Card.Body>
                    </Card>
                </Col>
                <Col   style={{paddingLeft: '10px',paddingTop: '5px', paddingRight: '10px',paddingBottom: '1px',width: '30rem' }}>
                <Card >
                        <Card.Body>
                        <h2 className="general-title">Status</h2>
                        </Card.Body>

                </Card>   
            </Col>
        </Grid>
      );
               
  }

  export default SendForm