import React from "react"

import { useState , useEffect} from "react";
import Container from 'react-bootstrap/Container';

import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';



const Domain= process.env.REACT_APP_DOMAIN

const columns = [
    { id: 'type', label: 'Código', minWidth: 50,align:'center'},
    { id: 'messageId', label: 'ID Mensaje', minWidth: 50, align:'center' },
    { id: 'status', label: 'Estatus', minWidth: 50,align:'center' },
    { id: 'contract', label: 'Contrato', minWidth: 50,align:'center' },
    { id: 'client', label: 'Cliente', minWidth: 50,align:'center' },

    { id: 'topic', label: 'Tópico', minWidth: 50,align:'center' },
    { id: 'to', label: 'Remitente', minWidth: 50,align:'center' },
    { id: 'template', label: 'Plantilla', minWidth: 50,align:'center' },
    { id: 'date', label: 'Fecha', minWidth: 50 ,align:'center'}

  ];


  
const rows =[];



function ClientList (props)  {
    const {view} = props
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows ,setRows] = useState([]);
    const [activities, setActivity] = useState(JSON.parse(localStorage.getItem("marketing")));
 

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    useEffect(() => {

     

      const createRows =()=> {

        const data2=activities.data;
        const array2=[];

        data2.forEach(function(element) 
        { 
            var contract="";
            var client = "";

            try {
             if(element.contracts[0]!=[]){
              contract=element.contracts[0].code
             } 
            } catch (error) {
              contract="NA"

            }
            try {
              if(element.clients[0]!=[]){
               client=element.clients[0].name;
              } 
             } catch (error) {
              client="NA"
 
             }
            array2.push([element.id,
              element.type,
              element.messageId,
              element.status,
              contract,
              client,
              element.topic,
              element.data.to,
              element.data.template.name,
              element.createdAt]);

        });
        setRows(array2);
          
      };
      createRows();
  } , []);   


   
    return (
      <Container>

        <Typography variant="h4" gutterBottom>
                Marketing
              </Typography>
            {/* {JSON.stringify(activities.data)} */}

      <TableContainer sx={{  minWidth:"100%"}}>
        <Table stickyHeader aria-label="sticky table" style={{margin: "auto"}}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth,fontSize: 12}}
                  
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>

                    {columns.map((column, index) => {
                      return (
                        <TableCell key={column.id} 
                        sx={{
                           margin: 1,
                          fontWeight: 'fontWeightLight',

                          fontSize: '0.7em',
                          backgroundColor: "#FAFBFB" }} 
                        align={column.align}>
                          {column.format && typeof row[index+1] === 'number'
                            ? column.format(row[index+1])
                            : row[index+1]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Container>


    )
};

export default ClientList;

       