// eslint-disable-next-line
import logo from '../logo.svg';
import '../App.css';
import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Drawer from './NavDrawer';
import Box from '@mui/material/Box';
import Navbar from './Navbar';

import { useNavigate } from "react-router-dom";

function SendMass () {

    const navigate = useNavigate();
    const view='MassiveSend';
    const [authenticated, setauthenticated] = useState(false)
  
    useEffect(() => {

        const checkAuth =()=> {
            if (!JSON.parse(sessionStorage.getItem("authenticated"))) 
            {
                navigate('../', { replace: true });
            } 
        };
        checkAuth();
    } , []);
  
    
  
        
        return (

            <Box>
                <Navbar></Navbar>
                <Drawer view={view}></Drawer>
            </Box>
      );
   
  }

  export default SendMass;