import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Papa from 'papaparse';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import useWebSocket from 'react-use-websocket';



const WS_URL = process.env.REACT_APP_SOCKET_URL;

const Domain= process.env.REACT_APP_DOMAIN

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
        margin: 1,
       fontWeight: 'fontWeightLight',
       fontSize: '0.7em',
       backgroundColor: "#FAFBFB" }
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function SendForm (){

    console.log(localStorage.getItem("templates"))

    const [fulTemplateData, setFullTemp] = useState(localStorage.getItem("templates"))
    const [sent,setSent]=useState({});
    const [client,setClient]=useState("");
    const [mobile,setMobile]=useState("");
    const [template,setTemplate]=useState("");
    const [text,SetText]=useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [loadedFile, setLoadedFile]=useState(false);
    const [CSVData, setCSVData]=useState({});
    const [tableRows, setRows]=useState([]);
    const [columns, setCols]=useState([]);

    const [show, setShow] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    const [selectedTemplate, setSelected] = useState('');
    const [msgHistory, setMessages] = useState([]); 
    const [socketID, setSocketID] = useState(''); 
    const [templateList, setTemplateList]=useState([]);
    const [accountList,setAccountList]=useState([]);
    const [account,setAccount]=useState(""); 
    const [account_data,setAccountData]=useState({}); 
    const [phones, setphones] = useState(JSON.parse(localStorage.getItem("phones")));
    // connect as client to socket.
    const connection =  useWebSocket(WS_URL);

    useEffect(()=>{
        if (connection.lastMessage !== null) {
            const arr = msgHistory

            if (JSON.parse(connection.lastMessage.data)['userId']){
                setSocketID(JSON.parse(connection.lastMessage.data).userId);
            }else{
                arr.push(JSON.parse(connection.lastMessage.data));
                setMessages( arr);
            }            
          }
    }, [connection.lastMessage, setMessages])

    useEffect(() => {
        window.addEventListener("beforeunload", onUnload);
    }, []);

    useEffect(()=>{

        const templateList = async()=> {

          
            const data= await JSON.parse(fulTemplateData);
            const array =[];
            data.data.forEach(function(element) 
            { 
                array.push(element.name);
            });
    
            setTemplateList(array);
              
          };

          const updatePhones = async () =>{
            try {      
              await axios.get(Domain+'/api/phones')
              .then(function(response){
                  setphones(response.data);
                  localStorage.setItem("phones", JSON.stringify(response.data));
      
              })
            } catch (err) {
      
              console.log(err);
            } 
          
          };

          const accountList =()=> {
            const data2= JSON.parse(localStorage.getItem("phones"));
            const array =[];
            data2.data.forEach(function(element) 
            { 
                array.push(element.name);
            });
    
            setAccountList(array);
              
          };

          try {
            updatePhones();
            templateList();
            accountList();  
          } catch (error) {
              
          }
            
    }, []);
    

    const onUnload=()=>{
       connection.sendMessage('close');
    }
    // Handle the selected message template

    const handleChange = (event) => {
        setSelected(event.target.value);
        setTemplate(event.target.value);
    };

    const handleChange_account=  (event) => {
        const account_name=event.target.value

        const data2= JSON.parse(localStorage.getItem("phones"));
        var selected_account ={};
        data2.data.forEach( async function (element) 
        { 
            if (element.name==account_name){
                selected_account = element

                try {       
                    await axios.get(Domain+'/api/wapp/templates/'+selected_account.phone_id+"&"+selected_account.account_id)
                   .then(function(response){
                        console.log(response.data.data)
                        localStorage.setItem("templates", JSON.stringify(response.data.data.message_templates));
      
                    
                        const data= JSON.parse(localStorage.getItem("templates"));

                        const array =[];
                        data.data.forEach(function(element) 
                        { 
                            array.push(element.name);
                        });
                
                        setTemplateList(array);

                        
                   })
                } catch (err) {
        
                console.log(err);
                } 
            }
            
        });

        setAccount(event.target.value);
        setAccountData(selected_account)
    
    };

    // Handle the file uploading
    // It also set rows to provide a file preview
    function onFileChange(event){
        setSelectedFile(event.target.files[0]);
        setLoadedFile(true)

        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setCSVData(results);
                
                if (results.data.length>2){
                    setRows(results.data.slice(0, results.data.length));

                }else{
                    setRows(results.data.slice(0, 3));

                }     
                setCols(results.meta.fields);
            },
          });

    };


    // 
    function handleshow(event){
        setShow(false);
        setSent("");
    };

    // Handle confirm button
    function handleConfirm(event){
        if (event.target.checked) {
            setConfirmed(true);
            setShowButton(true);

        } else {
            setShowButton(false);
            setConfirmed(false);
        }
    };

    // Handle upload and processing action
    function uploadAction(event){
        event.preventDefault();

        const formData = new FormData()
        formData.append('client', client);
        formData.append('template', template);
        formData.append('text', text);
        formData.append('file',selectedFile)

        const data2= JSON.parse(localStorage.getItem("phones"));
        var selected_account ={};

        data2.data.forEach( async function (element) 
        { 
            if (element.name==account){
                formData.append('phone_id',element.phone_id)
                formData.append('mobile', element.phone_number);
                formData.append('account_id', element.account_id);

            }
        });



        setShowButton(false);
        setConfirmed(false);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',  
            }
        }
        try {         
            axios.post(Domain+'/wapp/send_wapp',formData,config)
            .then(function(response){
                setSent(response.data.message);

            })
          } catch (err) {
            console.log(err);
          }
    };


    // We have to identify is a messages from the server
    // comes with an error in the Whatsapp response

    function renderTerminalLine(event) {
        if(Object.hasOwn(event, 'error')) {
            console.log(event);
          return (
             <div className="terminal__prompt">
                <div className="terminal__prompt__label_error">Error:  
                    <div className="terminal__line">{JSON.stringify( event.error.message)}</div>
                    <div className="terminal__line">{JSON.stringify( event.error.error_data)}</div>  
                </div>
            </div>          
                   
          );
        } else {
          return (
             <div className="terminal__prompt">
                <div className="terminal__prompt__label">Message ID:  
                    <div className="terminal__line">{JSON.stringify( event.messages[0].id)}</div>
                    <div className="terminal__line">{JSON.stringify( event.contacts[0])}</div>  
                 </div>
            </div> 
          );
        }
      }
    
    return(

        <Box>
        <Row>
            <Grid sx={{ flexGrow: 2 }} container spacing={{ xs: 4, md: 6}} 
            direction="row"
            justifyContent="center"
            alignItems="left" >

            <Grid item xs={2} sm={5} md={5} container>
                    
                    <Card >
                    <Card.Header>Carga de plantilla</Card.Header>
                    <Card.Body>
                        {sent === "Sent was registered successfully!" &&
                            <Alert show={!show}  variant="success">
                                ¡La solicitud de envío masivo está en procesamiento!
    
                            <div className="d-flex justify-content-end">
                                <Button onClick={handleshow} variant="outline-success">
                                    Cerrar
                                </Button>
                                </div>
                            </Alert>
                        }
                            
                            <Form onSubmit={uploadAction}>
   
                            <InputLabel id="demo-simple-select-helper-label">Seleccionar cuenta (teléfono)</InputLabel>
                            <Select style={{margin:"0px", marginBottom:"20px", height:"30px", width:"100%"}}
                                    value={account}
                                    onChange={handleChange_account}
                                    >

                                        {
                                            accountList.map((item)=> {
                                                return(<MenuItem value={item} >{item}</MenuItem> )
                                            })
                                        }

                                   
                                    </Select> 

                            <InputLabel id="demo-simple-select-helper-label">Seleccionar plantilla</InputLabel>
                            <Select style={{margin:"0px", marginBottom:"20px", height:"30px", width:"100%"}}
                                    value={template}
                                    onChange={handleChange}
                                    >

                                        {
                                            templateList.map((item)=> {
                                                return(<MenuItem value={item} >{item}</MenuItem> )
                                            })
                                        }

                                    {/* <MenuItem value={"test_template"}>test_template</MenuItem>
                                    <MenuItem value={"habeas_data_2"}>Habeas Data</MenuItem>
                                    <MenuItem value={"credenciales_de_acceso"}>credenciales_de_acceso</MenuItem>
                                    <MenuItem value={"marketing_comunicaciones"}>marketing_comunicaciones</MenuItem>
                                    <MenuItem value={"cursos_area_virtual"}>cursos_area_virtual</MenuItem>
                                    <MenuItem value={"curso_comunicacion_comfama"}>curso_comunicacion_comfama</MenuItem>
                                    <MenuItem value={"comunicacion_pruebas_de_estado_1"}>comunicacion_pruebas_de_estado_1</MenuItem>
                                    <MenuItem value={"general_recordatorio_eventos_1"}>general_recordatorio_eventos_1</MenuItem> */}
                                     
                                    </Select> 
                                <Form.Group controlId="formFile" className="mb-3">
                                    <InputLabel id="demo-simple-select-helper-label">Seleccionar archivo</InputLabel>
                                    <Form.Control type="file" onChange={onFileChange} />
                                </Form.Group>
    
                                
                                <br></br>
                                <Form.Group className="mb-3">
                                <Form.Check type="checkbox" label="confirmar el envío" enabled value={confirmed}  onChange={handleConfirm}/>
                                </Form.Group>
    
                                <Button disabled={!showButton} type="submit" onClick={uploadAction}>Enviar</Button>
                            </Form>
                            </Card.Body>
                        </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={6} >
    
                        <Card >
                            <Card.Header>
                            <h2 className="general-title">Status</h2>
                            </Card.Header>
                            <Card.Body>
                                <div className="terminal">

                                {msgHistory.map((msg) => (
                                    msg.data.msg ? <div className="terminal__line">{JSON.stringify(msg.data.msg)}</div> 
                                    :renderTerminalLine(msg.data)
                                ))}
                                    
                                </div>
                                                    
                            </Card.Body>
                        </Card>   
                </Grid>
            </Grid>

        </Row>
        <Row>
            <Container>
            {
                (function() {
                    if ( JSON.stringify(CSVData) === '{}') {
                        return (<Grid  justifyContent="center" alignItems="left"><Grid item>Para previsualizar sus datos, cargue un archivo de extensión CSV</Grid></Grid>);
                    } else {
                        return (
                            <TableContainer sx={{ maxHeight: 440, width:"100%", marginTop:10 }}>
                                <Table stickyHeader aria-label="sticky table a dense table"  sx={{ minWidth: 30 }} size="small" >
                                <TableHead>
                                    <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                        align="center"
                                        style={{ minWidth: 50,fontSize: 12}}
                                        >
                                        {column}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRows
                                    .map((row) => {
                                        return (
                                        <StyledTableRow hover  >

                                            {columns.map((column, index) => {
                                            return (
                                                <StyledTableCell  style={{width: '1px', whiteSpace: 'nowrap'}}>
                                                 {row[column]}
                                                </StyledTableCell>
                                            );
                                            })}
                                        </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                                </Table>
                            </TableContainer>
                        );
                    }
                    })()
            }
            </Container>
           </Row>
        </Box>
        );
               
  }

  export default SendForm