import React from "react"
import axios from 'axios';


import { useState , useEffect} from "react";


import Box from '@mui/material/Box';
import Card from 'react-bootstrap/Card';
import Grid from '@mui/material/Grid';
import { blue } from "@mui/material/colors";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';


import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Row from "react-bootstrap/esm/Row";
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import PhotoIcon from '@mui/icons-material/Photo';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';


import Export from '../Reports/excel';
import { CardActionArea } from "@mui/material";


const Domain= process.env.REACT_APP_DOMAIN

  
const rows =[];
const years = ['1','1','2'];



const setting =[{title:'Plantillas', size:'20%'},
{title:'Plantilla', size:'80%'}]


function Report ()  {
// templates
    
    const [templateList, setTemplateList]=useState([]);
    const [template,setTemplate]=useState("");
    const [selectedTemplate, setSelected] = useState({})

    const [account,setAccount]=useState(""); 
    const [accountList,setAccountList]=useState([]);
    const [account_data,setAccountData]=useState({}); 
    
    const Json_Template_structure = {}

    // templateView section
    const [template_title, setTitle]=useState(null); 

    const [jsonExport,setJsonExport]=useState({});
    
    

    const handleChange =  (event) => {
      const template_name = event.target.value
      const data = JSON.parse(localStorage.getItem("templates")).data
      data.forEach( function(element) 
      { 
          if (element.name==template_name){
            setSelected(element);
          }
      }); 
      setTemplate(template_name);
      


  };



      useEffect(()=>{

        const templateList =()=> {
            const data= JSON.parse(localStorage.getItem("templates")).data;
            const array =[];
            data.forEach(function(element) 
            { 
                array.push(element.name);
            });

            setTemplateList(array);
              
          };

          const accountList =()=> {
            const data2= JSON.parse(localStorage.getItem("phones"));
            const array =[];
            data2.data.forEach(function(element) 
            { 
                array.push(element.name);
            });
    
            setAccountList(array);
              
          };



          templateList();
          accountList();
      }, []);


  const handleChange_account=  (event) => {
        const account_name=event.target.value

        const data2= JSON.parse(localStorage.getItem("phones"));
        
        var selected_account ={};
        data2.data.forEach( async function (element) 
        { 
            if (element.name==account_name){
                selected_account = element

                try {       
                    await axios.get(Domain+'/api/wapp/templates/'+selected_account.phone_id+"&"+selected_account.account_id)
                   .then(function(response){
                        console.log(response.data.data)
                        localStorage.setItem("templates", JSON.stringify(response.data.data.message_templates));
      
                    
                        const data= JSON.parse(localStorage.getItem("templates"));

                        const array =[];
                        data.data.forEach(function(element) 
                        { 
                            array.push(element.name);
                        });
                
                        setTemplateList(array);

                        
                   })
                } catch (err) {
        
                console.log(err);
                } 
            }
            
        });

        setAccount(event.target.value);
        setAccountData(selected_account)
    
    };


    const toRender =()=> { 

      // This json is used to infer the structure of the template to produce an excel template


      const info = [<div><Divider>Info</Divider><CardHeader style={{backgroundColor: "#eceff1"}} title={selectedTemplate.name} subheader={"id:"+selectedTemplate.id}/></div>]

      if (selectedTemplate.components === undefined){

      }else{

        var index_buttons=0

        selectedTemplate.components.forEach(element => {


           console.log(element)
          if (element.type=="HEADER") {
            // Del Header solo puede mantener realmente UN UICO ELEMENTO
            if (element.format=="IMAGE" ){
              info.push(<div><Divider>Header</Divider> <CardContent style={{backgroundColor: "#e1f5fe"}}>
                <IconButton
                >
                  <PhotoIcon sx={{ fontSize: "80px" }} />
                </IconButton>
              </CardContent></div>)

              Json_Template_structure[element.type+"_image_link1"]= "";

            }else if (element.format=="VIDEO"){
              info.push(<div><Divider>Header</Divider> <CardContent style={{backgroundColor: "#e1f5fe"}}>
                <IconButton
                >
                  <PhotoIcon sx={{ fontSize: "80px" }} />
                </IconButton>
              </CardContent></div>)

              Json_Template_structure[element.type+"_video_link1"]= "";

            }
            else {
              info.push( <div><Divider>Header</Divider> <CardContent style={{backgroundColor: "#e1f5fe"}}>
                <Typography variant="button" >
                 {element.format}
                </Typography>
                <Typography variant="body1" color="text.primary">
               {element.text}
              </Typography>
              </CardContent></div>)
           
              const regex = regexMatch(element.text)

              if (regex===null){

              }else{
                
                regex.forEach(val => {
                  Json_Template_structure[element.type+val]= "";

                });
              }
               
            }
  
            
  
          } else if (element.type=="BODY") {
  
            
  
            info.push(<div><Divider>Body</Divider>  <CardContent style={{backgroundColor: "#e0f7fa"}}>
              <Typography variant="button" >
               {element.format}
              </Typography>
              <Typography variant="body1" color="text.secondary">
               {element.text}
              </Typography>
            </CardContent></div>)          
            
            const regex = regexMatch(element.text)

              if (regex===null){

              }else{
                
                regex.forEach(val => {
                  Json_Template_structure[element.type+val]= "";

                });
              }

  
          }else if(element.type=="FOOTER"){
            info.push( <div><Divider>Footer</Divider><CardContent  style={{backgroundColor: "#e0f2f1"}}>
              <Typography variant="button">
               {element.format}
              </Typography>
              <Typography variant="body1" color="text.secondary">
               {element.text}
              </Typography>
            </CardContent></div>)    
  
          }else if(element.type=="BUTTONS"){
            index_buttons=index_buttons+1

            element.buttons.forEach(button => {
              info.push(<div><Divider>Buttons</Divider> <CardContent style={{backgroundColor: "#fce4ec"}}>
                <Typography variant="button" >
                 {button.type}
                </Typography>
                <Button variant="contained" disabled>
                  {button.text}
                </Button>
              </CardContent></div>)  

                if (button.type=='URL'){
                  Json_Template_structure[element.type+"_" + button.type+index_buttons.toString()]= "";

                }else if (button.type=='QUICK_REPLY'){


                }else{

                }


            });
            
          }
          else{
  
          }
         });
      }
      console.log(Json_Template_structure);
      return info
    }


    const regexMatch =(string)=>{
      var regex = /(?<=(\{\{))([\w\s]*)(?=(\}\}))/gm;
      var match = string.match(regex);
      return match
    }

    const handleDownload = async (e) =>  {
      e.preventDefault()
    
      var info = Json_Template_structure
      info["client"]=""
      info["contract"]=""
      info["firstName"]=""
      info["lastName"]=""
      info["celular"]=""
      info["activity"]=""
      info["topic"]=""
      info["template"]=""
      						

      Export([info],'Plantilla_'+selectedTemplate.name);
    
    };


    return (
        <Box fluid sx={{padding:'1.2em', width:'85em'}}>
            <Grid sx={{ flexGrow: 1, width:'100%', }} container spacing={2}>
              <Grid item md={12}>
                <Grid container justifyContent="center" spacing='12'>
                    
                    <Grid key={setting[0].title} sx={{width:setting[0].size}} item>
                          <MenuList>
                              <MenuItem>
                                <ListItemText>Plantillas</ListItemText>
                              </MenuItem>

                              <MenuItem>

                                <ListItemText>Nuevo</ListItemText>

                              </MenuItem>

                              <MenuItem>
                                <ListItemText>Delete</ListItemText>

                              </MenuItem>
                              <Divider />
                             
                            </MenuList>
                    </Grid>

                    <Grid key={setting[1].title} sx={{width:setting[1].size}} item>
                    <Card >
                        
                        <Card.Body   sx={{justify:'right'}}>
                            <Grid style={{padding:'5px'}} container spacing={1}>
                            
                                 <Row >
                                 <Col sm={10}>
                                 <InputLabel id="demo-simple-select-helper-label">Seleccionar cuenta (teléfono)</InputLabel>
                                  <Select style={{margin:"2px", marginBottom:"20px", height:"30px", width:"100%"}}
                                    value={account}
                                    onChange={handleChange_account}
                                    >

                                        {
                                            accountList.map((item)=> {
                                                return(<MenuItem value={item} >{item}</MenuItem> )
                                            })
                                        }

                                   
                                    </Select>   
                                                               
                                  </Col>
                                 


                                  <Col sm={10}>
                                  <InputLabel id="demo-simple-select-helper-label">Seleccionar plantilla</InputLabel>

                                   <Select style={{margin:"2px", marginBottom:"20px", height:"30px", width:"100%"}}
                                    value={template}
                                    onChange={handleChange}
                                    >

                                        {
                                            templateList.map((item)=> {
                                                return(<MenuItem value={item} >{item}</MenuItem> )
                                            })
                                        }
                                    
                                    </Select> 
                                  </Col>
                                  <Col><Link
                                    href="https://business.facebook.com/wa/manage/message-templates/"
                                    target="_blank"
                                  >
                                    Ver en Whatsapp
                                  </Link></Col>    
                                </Row>  

                            </Grid>
                            <Grid   style={{padding:'5px'}} container spacing={1}>
                              
                             <Row  className="p-4">
                             <Card sx={{ maxWidth: 500 }} style={{padding:'5px'}} >
                                 {toRender().map(item=>{
                                  return(item)
                                 })}

                             </Card>

                             </Row>
                            </Grid>
                        </Card.Body>
                        <CardActionArea><Box justifyContent="flex-end" alignItems="flex-end"   display="flex" style={{padding:5}}>
                              <Button  variant="outlined" size="small" onClick={e=>handleDownload(e)}>
                                Descargar Formato
                              </Button>
                            </Box></CardActionArea>
                    </Card>
                    </Grid> 


                </Grid>
              </Grid>
              
            </Grid>
        </Box>
            
    )
};

export default Report;

       