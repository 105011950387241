import React from "react"
import axios from 'axios';

import { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";



const Domain= process.env.REACT_APP_DOMAIN

function Login ()  {

    const navigate = useNavigate();
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("")
    const [authenticated, setauthenticated] = useState(JSON.parse(sessionStorage.getItem("authenticated")));
    const [resp, setResp] =  useState({});

    useEffect(() => {

        const checkAuth =  () => {
          if (authenticated) 
          {
            navigate('../dashboard', { replace: true });
          } 
        };
      
        checkAuth();
      } , []);

    function handleSubmit(e) {
        e.preventDefault()

        
        const formData = new FormData()
        formData.append('username', username);
        formData.append('password', password);
        


        const config = {
            headers: {
                'Content-Type': 'application/json',  
            }
        }

        try {       
             axios.post(Domain+'/auth/signin',formData, config)
            .then(function(response){
                setResp(response.data)
                if (resp=={}){
                    console.log(response.data)
                   sessionStorage.setitem("authenticated", JSON.stringify(false));

                }else{

                    console.log(response.data)

                    sessionStorage.setItem("authenticated", JSON.stringify(true));
                    navigate('../dashboard', { replace: true });
                }
                     
            })
          } catch (err) {

            console.log(err);
          }
    };



    return (
        <div className="Auth-form-container">
            

        <form className="Auth-form" onSubmit={handleSubmit}>
            <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="form-group mt-3">
                <label>Username</label>
                <input
                type="text"
                className="form-control mt-1"
                placeholder="username"
                value={username}
                onChange={(e) => setusername(e.target.value)}
                />
            </div>
            <div className="form-group mt-3">
                <label>Password</label>
                <input
                type="password"
                className="form-control mt-1"
                placeholder="password"
                onChange={(e) => setpassword(e.target.value)}
                />
            </div>
            <div className="d-grid gap-2 mt-3">
                <button type="submit" className="btn btn-primary" value="Submit" >
                Submit
                </button>
            </div>
            <p className="forgot-password text-right mt-2">
                Forgot <a href="#">password?</a>
            </p>
            </div>
        </form>
        </div>
    )
};

export default Login;

       