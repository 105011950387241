import React from "react"
import axios from 'axios';

import { useState , useEffect} from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';

import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import NativeSelect from '@mui/material/NativeSelect';



const Domain= process.env.REACT_APP_DOMAIN

const Statuses = ['ACTIVO','INACTIVO','SUSPENDIDO','CANCELADO','TERMINADO']

function ContractForm(props)  {

  const {created} = props

  const [clientId ,setname] = useState('');
  const [Code,setCode] = useState('');
  const [Status ,setStatus] = useState('');

  const handleSubmit = async (e) =>  {
    e.preventDefault()
 
    const formData = new FormData()
    const current = new Date();

    formData.append('client_id', clientId);
    formData.append('code', Code);
    formData.append('status', Status);
    formData.append('date',`${current.getFullYear()}/${current.getMonth()+1}/${current.getDate()}`);

    console.log( `${current.getFullYear()}/${current.getMonth()+1}/${current.getDate()}`)

    const config = {
        headers: {
            'Content-Type': 'application/json',  
        }
    }
    try {       
        await axios.post(Domain+'/api/contract',formData, config)
        .then(function(res){
           
            if (res=={}){
              created(false); 
 

            }else{
              created(true); 
              
            }
                 
        })
      } catch (err) {
        created(false); 
        console.log(err);
      }

};


    useEffect(() => {

     
  } , []);   


   
    return (
      
      <Box
        component="form"
        sx={{
          width: 500,
          height: 400,
          backgroundColor: '#eceff1',
          
        }}
        noValidate
        autoComplete="off"
      >

      <Stack direction="column" alignItems="center" spacing={1} sx={{p:3}}>

      <FormControl>
        <FormHelperText id="id_cliente">Id Cliente</FormHelperText>
        <Input id="id_cliente" aria-describedby="Cliente" fullWidth onChange={event => setname(event.target.value)}
        sx={{
        width: { sm: 200, md: 300 },
        "& .MuiInputBase-root": {
            height: 80
        }
    }}/>
      </FormControl>
     
      <FormControl>
        <FormHelperText id="Code">Nombre/Código del contrato</FormHelperText>
        <Input id="Code" aria-describedby="Code" fullWidth onChange={event => setCode(event.target.value)}
        sx={{
          width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 80
          }
      }}/>
      </FormControl>

      <FormControl>
        <FormHelperText id="Status">Estatus</FormHelperText>

          <NativeSelect
          sx={{ width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 80
          } }}
          fullWidth 
          onChange={event => setStatus(event.target.value)}
          >
            <option ></option>

            {
                Statuses.map((status)=>{
                  return(
                    <option value={status}>{status}</option>
                  )
                })
              }
              
            </NativeSelect> 
      </FormControl>

    

      <Box justifyContent="flex-end" alignItems="flex-end"   display="flex" 
     sx={{
          p:3
      }} 
      >
        <Button  variant="outlined" size="small" onClick={e=>handleSubmit(e)}>
          Crear
        </Button>
      </Box>

      </Stack>

    </Box>
    )
};

export default ContractForm;

       