import '../../App.css';
import React, {useEffect, useState} from 'react';
import Drawer from '../NavDrawer';
import axios from 'axios';


import Box from '@mui/material/Box';
import Navbar from '../Navbar';


const Domain= process.env.REACT_APP_DOMAIN


function Sents(){
    const view='SentsMarketing';

    const [resp, setResp] =  useState({});

    useEffect(() => {

        const getSents=()=>{
            try {     
            
                const formData = new FormData()
                formData.append('activity', 'Marketing');
                const config = {
                    headers: {
                        'Content-Type': 'application/json',  
                    }
                }
    
                axios.post(Domain+'/api/sentsActivity',formData, config)
               .then(function(response){
                   
                   if (resp=={}){
    
                   }else{
                    
                    localStorage.setItem('marketing',JSON.stringify(response.data));
                        
                   }
                        
               })
             } catch (err) {
    
               console.log(err);
             }
    
        }

    

        getSents();
    } , []);   

    return (
        <Box sx={{ display: 'flex' }}>
                <Navbar></Navbar>
                <Drawer view={view}></Drawer>
        </Box>
                
    );
}

export default Sents