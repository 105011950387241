import React from "react"
import axios from 'axios';

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import CardDash from './DashComponents/CardsDash';
import Grid from '@mui/material/Grid';
import { areArraysEqual } from "@mui/base";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Line } from 'react-chartjs-2';

import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';



ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend);

const Domain = process.env.REACT_APP_DOMAIN






export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Tendencia de Mensajes',
    },
  },
};
const label=['january'];


function Report() {


  const [stats, setStats] = useState(localStorage.getItem('stats'));
  const [topic, setTopics] = useState([]);
  const [activity, setActivity] = useState([]);
  const [total, setTotal] = useState(0);
  const [month, setmonth] = useState(0);
  const [processed, setprocessed] = useState(0);
  const [errors, setErrors] = useState(0);



  const samp1 = {
    datasets: [
      {
        label: "Orders Count",
        data:[1,2,3],
        backgroundColor: ["rgb(255, 99, 132)", "rgb(255, 99, 0)", "rgb(255, 0, 132)"]
      }
    ],
    labels: ["1","2","3"]
  };

  
  const [dataSamp, setDataSamp] = useState(samp1);


  const dataL = {
    label,
    datasets: [
        {
          label: 'Errores',
          data: [10],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Envíos exitosos',
          data: [1],
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };

    const [dataLine, setDataLine] = useState(dataL);



  const itemList = [];
  const itemList2 = [];

  
  
  {/* <Grid  xs={5}> <CardDash value={item.count} header={item.activity}></CardDash></Grid> */ }
  useEffect( () => {


    const setArrays = () => {


      try {
        const arr = JSON.parse(stats).data.groupingActivity;
        const arr2 = JSON.parse(stats).data.groupingTopic;
        const fullJson = JSON.parse(stats)



      const arrHistoryErrors=[]
      const arrHistorySuccess=[]
      const labelsHistory = []

      const arraData=[];
      const arraLabel =[];


      // Settting arrays for pie plot

            arr.forEach(function (item, index) {
                itemList.push(<Grid item xs={5}> <CardDash value={item.count} header={item.activity} colorbck="#311b92"></CardDash></Grid>);
                
              });

            arr2.slice(0, 4).forEach(function (item, index) {
                itemList2.push(<Grid  item xs={5}> <CardDash  value={item.count} header={item.topic} colorbck="#1565c0"></CardDash></Grid>);
                arraData.push(parseInt(item.count));
                arraLabel.push(item.topic);
              });


              const samp = {
                datasets: [
                  {
                    label: "Orders Count",
                    data:arraData,
                    backgroundColor: ["rgb(255, 99, 132)", "rgb(255, 99, 0)", "rgb(255, 99, 200)","rgb(255, 200, 200)"]
                  }
                ],
                labels: arraLabel
              };





            //   // Settting arrays for time.line plots

              // stats.data.historyError.forEach(function (item, index) {
              //   arrHistoryErrors.push(item.count);
              // });

              // stats.data.historySuccess.forEach(function (item, index) {
              //   arrHistorySuccess.push(item.count);
              // });



            //   // labelsHistory = stats.data.alldates;

              const dataLine2 = {
                labels:fullJson.data.timeline.dates,
                datasets: [
                    {
                      label: 'Errores',
                      data: fullJson.data.timeline.errors,
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                      label: 'Envíos exitosos',
                      data: fullJson.data.timeline.successes,
                      borderColor: 'rgb(53, 162, 235)',
                      backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                  ],
                };

                
              setDataLine(dataLine2)
              setActivity(itemList);
              setTopics(itemList2);
              setDataSamp(samp);

              setTotal(JSON.parse(stats).data.total)
              setmonth(JSON.parse(stats).data.month)
              setprocessed(JSON.parse(stats).data.success[0].processed)
              setErrors(JSON.parse(stats).data.errors[0].errors)
      } catch (error) {

        console.log("no Data Graph Report")
        
      }
      
        };

        setArrays();

    }, []);
    

    return (
        <Container>
        <Row >
                <h2 className="general-title" style={{'color':'#808080'}}>Dashboard</h2> 
        </Row>
        <Row>
            <Col fluid style={{height:'20rem',paddingLeft: '5px',paddingTop: '5px', paddingRight: '20px',paddingBottom: '20px'}}>
                    <Doughnut data={dataSamp}/>
            </Col>
            <Col fluid style={{height:'20rem',paddingLeft: '5px',paddingTop: '5px', paddingRight: '20px',paddingBottom: '20px'}}>
                    <Line  options={options} data={dataLine}/>
            </Col> 
        </Row>
        <Row>
            <Col sm={6} fluid style={{height:'10rem',paddingLeft: '5px',paddingTop: '5px', paddingRight: '20px',paddingBottom: '20px'}}>
               <Card>
                <Card.Header>
                    <h2 className="general-title" style={{'color':'#808080'}}>Actividad</h2>  
                </Card.Header>
                <Card.Body>
                <Grid style={{padding:'10px'}} container spacing={1}>
                    {activity}
               </Grid>
               <Grid  style={{padding:'10px'}} container spacing={1}>
                    {topic}
               </Grid>
                    
                </Card.Body>
               </Card>
            </Col>
            <Col sm={6}fluid style={{'color':'#808080',height:'10rem',paddingLeft: '5px',paddingTop: '5px', paddingRight: '5px',paddingBottom: '20px'}}>
                <Card>
                <Card.Header>
                    <h2 className="general-title" style={{'color':'#808080'}}>Estadística de Envíos</h2>  

                </Card.Header>
                <Card.Body>
                  
                    <Grid container spacing={1}>
                    <Grid item xs={5}>
                    <CardDash value={total} header="Mensajes Enviados" colorbck="#607d8b"></CardDash>
                    </Grid>
                    <Grid item xs={5}>
                    <CardDash value={month} header="Este mes" colorbck="#607d8b" ></CardDash>
                    </Grid>
                    <Grid item xs={5}>
                    <CardDash value={processed} header="Exitosos" colorbck="#00897b"></CardDash>
                    </Grid>
                    <Grid item xs={5}>
                    <CardDash value={errors} header="Error" colorbck="#ff5252"> </CardDash>
                    </Grid> 
                    </Grid>
                                   
                    
                        
                </Card.Body>
               </Card>            
            </Col> 
        </Row>
    </Container>
    )
};

export default Report;

       