// eslint-disable-next-line
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Report from "./components/Reports/Reporte";
import Dash from "./components/Dashboard";
import Logout from "./components/Logout";
import SendMassive from "./components/SendMasive";
import SendIndividual from "./components/SendIndividual";
import Clients from "./components/Client/Clients";
import Contracts from "./components/Contracts/Contracts";
import SentsMarketing from "./components/Sents/SentsMarketing";
import SentsEducation from "./components/Sents/SentsEducation";
import Contacts from "./components/Contact/Contact";
import Templates from "./components/Template/Template";
import Config from "./components/Config/Config";

import {AuthControl, check,setAuth,unsetAuth} from "./controllers/auth.controller"

const root = ReactDOM.createRoot(document.getElementById('root'));

//Variables initialization 

root.render(

  <React.StrictMode>       
    <BrowserRouter>
      <Routes>
        <Route index element={ <Login />} />
        <Route path="dashboard" element={<Dash></Dash>} />
        <Route path="reports" element={ <Report/>} />
        <Route path="logout" element={ <Logout/>} />
        <Route path="massive" element={ <SendMassive/>} />
        <Route path="individual" element={ <SendIndividual/>} />
        <Route path="clients" element={ <Clients/>} />
        <Route path="contracts" element={ <Contracts/>} />
        <Route path="contacts" element={ <Contacts/>} />
        <Route path="marketing" element={ <SentsMarketing/>} />
        <Route path="education" element={ <SentsEducation/>} />

        <Route path="templates" element={ <Templates/>} />
        <Route path="config" element={ <Config/>} />

      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
