import { styled, useTheme } from '@mui/material/styles';

import React, {useEffect, useState} from 'react';

import SendIndividual from './Send_Form_Individual';
import SendFormMassive from './Send_Form_Massive';
import ClientList from './Client/Client_list';
import ContactView from './Contact/Contact_view';
import ContracList from './Contracts/Contract_list';
import SentsViewMarketing from './Sents/Sents_view_marketing';
import SentsViewEducation from './Sents/Sents_view_education';
import ReportsView from './Reports/Report_view';
import TemplateView from './Template/Template_view'
import ConfigView from './Config/Config_list';

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


import Drawer from '@mui/material/Drawer';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';


import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ChatIcon from '@mui/icons-material/Chat';
import SchoolIcon from '@mui/icons-material/School'; 
import Report from '@mui/icons-material/Assessment'; 
import People from '@mui/icons-material/People'; 
import ContractIcon from '@mui/icons-material/Feed'; 
import PhoneIcon from '@mui/icons-material/Phone';
import Ballot from '@mui/icons-material/Ballot';
import Toolbar from '@mui/material/Toolbar';
import Settings from '@mui/icons-material/Settings';


import GraphReport from './GraphReport';

const drawerWidth = 190;

function NavDrawer(props){

    const navigate = useNavigate();

    const { window, view } = props;


    const [mobileOpen, setMobileOpen] = useState(false);
    // const [view, setView] = useState( localStorage.getItem("view"));
    const menuItems = [
      {
        text: 'Formación',
        icon: SchoolIcon,
        onClick:"../education",
      },
      {
        text: 'Marketing',
        icon: ChatIcon,
        onClick: "../marketing",
      },
      {
        text: 'Reportes',
        icon: Report,
        onClick: "../reports",
      },
      {
        text: 'Plantillas',
        icon: Ballot,
        onClick: "../templates",
      }, 
    ];

    const menuItems2 = [
      {
        text: 'Clientes',
        icon: People,
        onClick:"../clients",
      },
      {
        text: 'Contratos',
        icon: ContractIcon,
        onClick: "../contracts",
      },
      {
        text: 'Contacts',
        icon: PhoneIcon,
        onClick: "../contacts",
      },
      {
        text: 'Configuración',
        icon: Settings,
        onClick: "../config",
      }
    ];

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
          <Toolbar />
          <Divider />
          <List>
          {menuItems.map(({ text, icon: Icon, onClick}, index) => (
            <ListItem button  key={text} to={onClick} component={Link}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} className="fontDrawer" />
            </ListItem>
          ))}
           
          </List>
          <Divider />
          <List>
          {menuItems2.map(({ text, icon: Icon, onClick}, index) => (
            <ListItem button  key={text} to={onClick} component={Link}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} className="fontDrawer" />
            </ListItem>
          ))}
          </List>
        </div>
      );
    
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
      
        <Box sx={{ display: 'flex', marginTop:'80px' }}>

        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          

          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="permanent"
            anchor="left"
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        
            {view=='Dashboard'
                ? <Box style={{ display: 'flex' }} ><GraphReport></GraphReport> </Box>
                : <p></p>
            }
            {view=='IndividualSend'
                ? <Box style={{minWidth:'80%'}} ><SendIndividual></SendIndividual></Box>
                : <p></p>
            }
            {view=='MassiveSend'
                ?<Box style={{ minWidth:'80%' }} > <SendFormMassive></SendFormMassive></Box>
                : <p></p>
            }
            {
              view=='Clients'
              ?<Box style={{ display: 'flex' }} > <ClientList></ClientList></Box>
              : <p></p>
            }
            {
              view=='Contacts'
              ?<Box style={{ display: 'flex' }} > <ContactView></ContactView></Box>
              : <p></p>
            }
            {
              view=='Contracts'
              ?<Box style={{ display: 'flex' }} > <ContracList></ContracList></Box>
              : <p></p>
            }
            {
              view=='SentsMarketing'
              ?<Box style={{ display: 'flex' }} > <SentsViewMarketing view={view} ></SentsViewMarketing></Box>
              : <p></p>
            }
            {
              view=='SentsEducation'
              ?<Box style={{ display: 'flex' }} > <SentsViewEducation view={view}  ></SentsViewEducation></Box>
              : <p></p>
            } 
            {
              view=='Reports'
              ?<Box style={{ display: 'flex' }} > <ReportsView view={view}  ></ReportsView></Box>
              : <p></p>
            } 
            {
              view=='Template'
              ?<TemplateView view={view}  ></TemplateView>
              : <p></p>
            }
             {
              view=='Config'
              ?<ConfigView view={view}  ></ConfigView>
              : <p></p>
            } 
      </Box>
      );

}

export default NavDrawer