import * as React from 'react';

import Button from '@mui/material/Button';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Container } from '@mui/system';


function SimpleDialog(props) {
    const { onClose, selectedValue, open, data } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const changeField = (value)=>{
        data.phone=value.target.value;
      
    };
    const changeFieldLast = (value)=>{
        data.lastName=value.target.value;
      
    }; 
    const changeFieldName = (value)=>{
        data.firstName=value.target.value;
      
    }; 
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
        <Box>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Editar contacto</DialogTitle>
                <DialogContent>

            <Container style={{margin:"10px"}}>
                <TextField
                label="Nombre"
                id="outlined-size-small"
                size="small"
                defaultValue={data.firstName}
                onChange={(value) => {changeFieldName(value)}}

                >
                    
                </TextField>
            </Container>


            <Container style={{margin:"10px"}}>
                <TextField
                label="Apellido"
                id="outlined-size-small"
                size="small"
                defaultValue={data.lastName}
                onChange={(value) => {changeFieldLast(value)}}

                />
            </Container>

            <Container style={{margin:"10px"}}>

                <TextField
                label="Celular"
                id="outlined-size-small"
                size="small"
                defaultValue={data.phone}
                onChange={(value) => {changeField(value)}}
                />
             </Container>
             </DialogContent>
             <DialogActions>

            <Button color="primary" sx={{ p: '10px' }} aria-label="directions " onClick={() => handleListItemClick('addAccount')}>
                Actualizar
            </Button>
            </DialogActions>
                
            </Dialog>
        </Box>
    );
  }
  
  export default SimpleDialog