import React from "react"
import axios from 'axios';


import { useState , useEffect} from "react";

import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Card from 'react-bootstrap/Card';
import Grid from '@mui/material/Grid';
import { blue } from "@mui/material/colors";
import DatePicker from './DatePicker';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';

import Export from './excel';


const Domain= process.env.REACT_APP_DOMAIN

  
const rows =[];
const years = ['1','1','2'];



const setting =[{title:'Reporte x Cliente', size:'40%'},
{title:'Habeas Data', size:'30%'},
{title:'Reporte x Tópico', size:'30%'}]


function Report ()  {
// Client Report Section
    const [date1, setDate1] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [clients, setClients] = useState(JSON.parse(localStorage.getItem("clients")));
    const [clientList ,setRows] = useState([]);
    const [disableContract ,setDisableContract] = useState(true);

    const [contracts, setContracts] = useState(JSON.parse(localStorage.getItem("contracts")));
    const [contractList ,setRowsContract] = useState([]);
    const [response ,setResponse] = useState({});
    const [selectedClient ,setSelectedClient] = useState('');
    const [selectedContract ,setSelectedContract] = useState('');


    // Habeas data Section

    const [dateHabeas1, setDateHabeas1] = useState(new Date());
    const [dateHabeas2, setDateHabeas2] = useState(new Date());
    const [selectedClientHabeas,setSelectedClientHabeas] = useState('');
    const [responseHabeas,setResponseHabeas] = useState({});
 

    // Client Repor Section

    const pull_data  =(target)=>{    
        setDate1(new Date(target))
      };

    const pull_data2  =(target)=>{    
      setDate2(new Date(target))
      };

    const handleClient  =(event)=>{    
      setSelectedClient(event.target.value)
      if (event.target.value==''){
        setDisableContract(true);
      }else{
        setDisableContract(false);
      }
    };

    const handleContract  =(event)=>{    
      setSelectedContract(event.target.value)
      
    };


    // Habeas Section
    const pull_data_habeas  =(target)=>{    
      setDateHabeas1(new Date(target))
    };

  const pull_data_habeas2  =(target)=>{    
    setDate2(new Date(target))
    };

  const handleClientHabeas  =(event)=>{    
    setSelectedClientHabeas(event.target.value)
    
  };





    useEffect(() => {

      const createRows =()=> {
        const data=clients.data;
        const array =[];
        data.forEach(function(element) 
        { 
            array.push({id:element.id,name:element.name});
        });

        setRows(array);
          
      };

      const createRowsContract =()=> {
        const data=contracts.data;
        const array =[];
        data.forEach(function(element) 
        { 
            array.push({id:element.id,name:element.code});
        });

        setRowsContract(array);
          
      };
      createRows();
      createRowsContract();

  } , []);  


  const handleSubmit = async (e) =>  {
    e.preventDefault()
 
    const formData = new FormData()

    if(selectedClient=='' ){
      formData.append('startDate', date1);
      formData.append('endDate', date2);
    }else if(selectedContract=='' ){
      formData.append('startDate', date1);
      formData.append('endDate', date2);
      formData.append('clientName', selectedClient);

    }else{
      formData.append('startDate', date1);
      formData.append('endDate', date2);
      formData.append('clientName', selectedClient);
      formData.append('contract', selectedContract);
    } 

    const config = {
        headers: {
            'Content-Type': 'application/json',  
        }
    }
    try {       
          await axios.post(Domain+'/api/client_report',formData, config)
        .then(function(res){
            setResponse(res.data)
            if (response=={}){
               

            }else{

              const info = dataPreparation(res.data.data);
              console.log(info);
              const date=new Date()
              Export(info,'Reporte'+date.toDateString());
            }
                 
        })
      } catch (err) {

        console.log(err);
      }

};


const handleSubmitHabeas = async (e) =>  {
  e.preventDefault()

  const formData = new FormData()

  if(selectedClientHabeas=='' ){
    formData.append('startDate', dateHabeas1);
    formData.append('endDate', dateHabeas2);
  }else {
    formData.append('startDate', dateHabeas1);
    formData.append('endDate', dateHabeas2);
    formData.append('clientName', selectedClientHabeas);

  }

  const config = {
      headers: {
          'Content-Type': 'application/json',  
      }
  }
  try {       
        await axios.post(Domain+'/api/habeas/stats',formData, config)
      .then(function(res){
          setResponseHabeas(res.data)
          if (response=={}){
             

          }else{

            const info = dataPreparationHabeas(res.data);
            console.log(info);
            const date=new Date()
            Export(info,'Reporte'+date.toDateString());
          }
               
      })
    } catch (err) {

      console.log(err);
    }

};

  const dataPreparation =(json)=> {
    var dataArr = [];

    json.forEach((row)=>{

      if(row.hasOwnProperty('clients') && row.hasOwnProperty('contracts')){
        dataArr.push({id:row.id, 
          type:row.type,
          status:row.status,
          messageId:row.messageId,
          activity:row.activity,
          topic:row.topic,
          contact:row.data.to,
          template:row.data.template.name,
          date:row.createdAt,
          client:row.clients[0].name,
          contract:row.contracts[0].code
        }) 
      }
      else if(row.hasOwnProperty('clients')){
        dataArr.push({id:row.id, 
          type:row.type,
          status:row.status,
          messageId:row.messageId,
          activity:row.activity,
          topic:row.topic,
          contact:row.data.to,
          template:row.data.template.name,
          date:row.createdAt,
          client:row.clients[0].name
        })
      }else{
        dataArr.push({id:row.id, 
          type:row.type,
          status:row.status,
          messageId:row.messageId,
          activity:row.activity,
          topic:row.topic,
          contact:row.data.to,
          template:row.data.template.name,
          date:row.createdAt
        })  
      }

      

    });

    return dataArr

  };
 

  const dataPreparationHabeas =(json)=> {
    var dataArr = [];

    json.data.forEach((habeas)=>{

      json.contacts.forEach((contact)=>{
        if (contact.id==habeas.contactId){

          dataArr.push({id:habeas.id, 
            aprobado:habeas.flag,
            data:habeas.data,
            contacto:contact.id,
            nombre:contact.firstName,
            apellido:contact.lastName,
            celular:contact.phone,
            fecha:habeas.updatedAt
          })
        }else{

        }

      })
    })
    return dataArr

    
  }


    return (
        <Box fluid sx={{padding:'1.2em', width:'85em'}}>
            <Grid sx={{ flexGrow: 1, width:'100%', }} container spacing={2}>
              <Grid item md={12}>
                <Grid container justifyContent="center" spacing='12'>
                    
                    <Grid key={setting[0].title} sx={{width:setting[0].size}} item>
                      <Card >
                        <Card.Header>
                            <h2 className="general-title    " style={{'color':'#808080'}}>{setting[0].title}</h2>  
                        </Card.Header>
                        <Card.Body   sx={{justify:'right'}}>
                            <Grid style={{padding:'5px'}} container spacing={1}>
                            
                            <Box sx={{
                                backgroundColor: '#e8eaf6',
                                borderRadius: 2
                                
                                }}>

                            Desde:
                             <DatePicker selectedDate={pull_data} ></DatePicker>
                             <br></br>

                            Hasta:
                             <DatePicker selectedDate={pull_data2} ></DatePicker>

                            </Box>

                            </Grid>
                            <Grid   style={{padding:'5px'}} container spacing={1}>
                              <FormControl sx={{ m: 1 }} variant="standard">
                                <InputLabel >Cliente</InputLabel>
                                <NativeSelect
                                sx={{ width: '20em' }} 
                                onChange={(event) => handleClient(event)}
                                >
                                  <option ></option>

                                 {
                                      clientList.map((client)=>{
                                        return(
                                          <option value={client.name}>{client.name}</option>
                                        )
                                      })
                                    }
                                    
                                  </NativeSelect>
                              </FormControl>
                              <FormControl sx={{ m: 1 }} variant="standard">
                                <InputLabel >Contrato</InputLabel>
                                <NativeSelect
                                sx={{ width: '20em' }} 
                                onChange={(event) => handleContract(event)}
                                disabled={disableContract}
                                >
                                    <option ></option>

                                 {

                                      contractList.map((contract)=>{
                                        return(
                                          <option value={contract.id}>{contract.name}</option>
                                        )
                                      })
                                    }
                                    
                                </NativeSelect>
                             </FormControl>

                            </Grid>
                            <Box justifyContent="flex-end" alignItems="flex-end"   display="flex">
                              <Button  variant="outlined" size="small" onClick={e=>handleSubmit(e)}>
                                Descargar
                              </Button>
                            </Box>
                            
                        </Card.Body>
                    </Card>
                    </Grid>

                    <Grid key={setting[1].title} sx={{width:setting[1].size}} item>
                    <Card >
                        <Card.Header>
                            <h2 className="general-title    " style={{'color':'#808080'}}>{setting[1].title}</h2>  
                        </Card.Header>
                        <Card.Body   sx={{justify:'right'}}>
                            <Grid style={{padding:'5px'}} container spacing={1}>
                            
                            <Box sx={{
                                backgroundColor: '#e8eaf6',
                                borderRadius: 2
                                
                                }}>

                            Desde:
                             <DatePicker selectedDate={pull_data_habeas} ></DatePicker>
                             <br></br>

                            Hasta:
                             <DatePicker selectedDate={pull_data_habeas2} ></DatePicker>

                            </Box>

                            </Grid>
                            <Grid   style={{padding:'5px'}} container spacing={1}>
                              <FormControl sx={{ m: 1 }} variant="standard">
                                <InputLabel >Cliente</InputLabel>
                                <NativeSelect
                                sx={{ width: '20em' }} 
                                onChange={(event) => handleClientHabeas(event)}
                                >
                                  <option ></option>

                                 {
                                      clientList.map((client)=>{
                                        return(
                                          <option value={client.name}>{client.name}</option>
                                        )
                                      })
                                    }
                                    
                                  </NativeSelect>
                              </FormControl>
                             
                            </Grid>
                            <Box justifyContent="flex-end" alignItems="flex-end"   display="flex">
                              <Button  variant="outlined" size="small" onClick={e=>handleSubmitHabeas(e)}>
                                Descargar
                              </Button>
                            </Box>
                            
                        </Card.Body>
                    </Card>
                    </Grid>



                    <Grid key={setting[2].title} sx={{width:setting[2].size}} item>
                      <Card >
                        <Card.Header>
                            <h2 className="general-title" style={{'color':'#808080'}}>{setting[2].title}</h2>  
                        </Card.Header>
                        <Card.Body>
                        <Grid style={{padding:'5px'}} container spacing={1}>
                    </Grid>
                    <Grid  style={{padding:'5px'}} container spacing={1}>
                    </Grid>
                            
                        </Card.Body>
                    </Card>
                    </Grid>


                </Grid>
              </Grid>
              
            </Grid>
        </Box>
            
    )
};

export default Report;

       