import React from "react"
import axios from 'axios';

import { useState , useEffect} from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ContractForm from './Contract_form';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const Domain= process.env.REACT_APP_DOMAIN

const columns = [
    {id: 'id', label:'ID', minWidth:100},
    { id: 'code', label: 'Código', minWidth: 170 },
    { id: 'status', label: 'Estatus', minWidth: 100 },
    {
      id: 'date',
      label: 'Fecha',
      minWidth: 170,
      align: 'right',
    },
    {
      id: 'client',
      label: 'Cliente',
      minWidth: 170,  
      align: 'right'
    },
    {
      id: 'create',
      label: 'Creado',
      minWidth: 170,
      align: 'right',
    },
  ];
  
  
const rows =[];

function ClientList ()  {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [clients, setClients] = useState(JSON.parse(localStorage.getItem("clients")));
    const [contracts, setContracts] = useState(JSON.parse(localStorage.getItem("contracts")));
    const [rows ,setRows] = useState([]);
 

    const [showNew, setShow] = useState(false);
    const [selectedrow, setSelectedRow] = useState();

    const handleShow = (event) => {
      setShow(!showNew)
    
    }

    const handleSelect = (id)=>{
      console.log(id)
      setSelectedRow(id);
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };


    const updateClients = async () =>{
      try {      
        await axios.get(Domain+'/api/contracts')
        .then(function(response){
            setClients(response.data);
            localStorage.setItem("contracts", JSON.stringify(response.data));
        })
      } catch (err) {

        console.log(err);
      } 
    
      window.location.reload(false);
    }


    const setCreated  = async (target)=>{    
      setShow(false);
      updateClients();
     
    };

    const remove  = async ()=>{    

      try {      
        await axios.get(Domain+'/api/removecontract/'+selectedrow)
        .then(function(response){
          console.log(response)
            if (response.data.message=="Success"){

              updateClients();
 
            }
        })
      } catch (err) {

        console.log(err);
      } 
    
    };

    useEffect(() => {

      const createRows =()=> {
        const data=contracts.data;
        const array =[];
        data.forEach(function(element) 
        { 
            array.push([element.id,element.code, element.contract_status, element.fecha, element.clientId, element.createdAt]);
        });

        setRows(array);
          
      };
      createRows();
  } , []);   


   
    return (
        
      <Box sx={{ flexGrow: 1}}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={11}>
          <Item  elevation={0}>
          {
              showNew==true
              ? <div>
                  <ContractForm created={setCreated}></ContractForm>
              </div>
              : <div>
                    <TableContainer sx={{ maxHeight: 440 }}>
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow >
                                    {columns.map((column) => (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                      >
                                        {column.label}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row,rowIndex) => {

                                      return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row[0]}  name={row[0]} 
                                        enableRowSelection
                                        onClick={() => handleSelect(row[0])}
                                        selected = {selectedrow== row[0]? true : false}
                                        >


                                          {columns.map((column, index) => {
                                            return (
                                              <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof row[index] === 'number'
                                                  ? column.format(row[index])
                                                  : row[index]}
                                              </TableCell>
                                            );
                                          })}
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <TablePagination
                              rowsPerPageOptions={[10, 25, 100]}
                              component="div"
                              count={rows.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
              </div>
 
          }
                   
          </Item>
        </Grid>
        <Grid item xs={1}>
          <Item elevation={0}>
          <Box >
          <Stack direction="column" alignItems="center" spacing={1}>
                <div>
                <IconButton  aria-label="Nuevo cliente " component="label" onClick={(event) => { handleShow(event) }}
                sx={{ 
                  color: "white", 
                  backgroundColor: "#e91e63", 
                  borderRadius: "50%" 
                }}
              
                >
                <AddIcon />
              </IconButton>
                </div>
                <div>
                <IconButton color="primary" aria-label="Borrar cliente " component="label" onClick={remove }
                sx={{ 
                  color: "white", 
                  backgroundColor: "#e91e63", 
                  borderRadius: "50%" 
                }}>
                <DeleteIcon />
              </IconButton>
                </div>

          </Stack>

            </Box>
          </Item>
        </Grid>
  

      </Grid>
    </Box>
        
        
        
        
        
        
        
        
        
  )
};

export default ClientList;

       