import React from "react"
import axios from 'axios';

import { useState , useEffect} from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';

import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const Domain= process.env.REACT_APP_DOMAIN

function ClientForm(props)  {

  const {created} = props

  const [name ,setname] = useState('');
  const [address,setAddr] = useState('');
  const [email ,setEmail] = useState('');
  const [contact ,setContact] = useState('');
  const [phone ,setphone] = useState('');

  const handleSubmit = async (e) =>  {
    e.preventDefault()
 
    const formData = new FormData()

    formData.append('name', name);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('contact', contact);

    const config = {
        headers: {
            'Content-Type': 'application/json',  
        }
    }
    try {       
        await axios.post(Domain+'/api/client',formData, config)
        .then(function(res){
           
            if (res=={}){
              created(false); 
 

            }else{
              created(true); 
              
            }
                 
        })
      } catch (err) {
        created(false); 
        console.log(err);
      }

};


    useEffect(() => {

     
  } , []);   


   
    return (
      
      <Box
        component="form"
        sx={{
          width: 500,
          height: 400,
          backgroundColor: '#eceff1',
          
        }}
        noValidate
        autoComplete="off"
      >

      <Stack direction="column" alignItems="center" spacing={1} sx={{p:3}}>

      <FormControl>
        <FormHelperText id="nombre">Nombre</FormHelperText>
        <Input id="nombre" aria-describedby="Nombre" fullWidth onChange={event => setname(event.target.value)}
        sx={{
        width: { sm: 200, md: 300 },
        "& .MuiInputBase-root": {
            height: 80
        }
    }}/>
      </FormControl>
     
      <FormControl>
        <FormHelperText id="contacto">Persona Contacto</FormHelperText>
        <Input id="contacto" aria-describedby="Contacto" fullWidth onChange={event => setContact(event.target.value)}
        sx={{
          width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 80
          }
      }}/>
      </FormControl>

      <FormControl>
        <FormHelperText id="Telefono">Teléfono</FormHelperText>
        <Input id="Teléfono" aria-describedby="Teléfono" fullWidth onChange={event => setphone(event.target.value)}
        sx={{
          width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 80
          }
      }}/>
      </FormControl>

      <FormControl>
        <FormHelperText id="Email">Email</FormHelperText>
        <Input id="email" aria-describedby="Email" onChange={event => setEmail(event.target.value)}
        sx={{
          width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 80
          }
      }}/>
      </FormControl>
    
      <FormControl>
        <FormHelperText id="Direccion">Dirección</FormHelperText>
        <Input id="Direccion" aria-describedby="Direccion" fullWidth onChange={event => setAddr(event.target.value)}
        sx={{
          width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 80
          }
      }}/>
      </FormControl>

      <Box justifyContent="flex-end" alignItems="flex-end"   display="flex" 
     sx={{
          p:3
      }} 
      >
        <Button  variant="outlined" size="small" onClick={e=>handleSubmit(e)}>
          Crear
        </Button>
      </Box>

      </Stack>

    </Box>


    )
};

export default ClientForm;

       