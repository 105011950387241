import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import { CardHeader } from '@mui/material';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function BasicCard( props) {
    const {value, header,colorbck}=props;

  return (
    <Container >
        <Card >
        <CardHeader
        title={<Typography sx={{ fontSize: 10 }} color="black" >{header}</Typography>}
        >
            
        </CardHeader>
        <CardContent style={{backgroundColor:colorbck}}>
        
            <Typography color="white" variant="h3" component="div">
            {value}
            </Typography>
        </CardContent>
        {/* <CardActions>
            <Button size="small">Learn More</Button>
        </CardActions> */}
        </Card>
    </Container>
    
  );
}