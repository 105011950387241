import '../../App.css';
import React, {useEffect, useState} from 'react';
import Drawer from '../NavDrawer';
import Box from '@mui/material/Box';
import Navbar from '../Navbar';

function Contracts(){
    const view='Contracts';
    const [contracts, setContracts] = useState(localStorage.getItem("contracts"));

    return (
        
        <Box sx={{ display: 'flex' }}>
            <Navbar></Navbar>
            <Drawer view={view}></Drawer>
        </Box>
    );
}

export default Contracts