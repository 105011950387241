import React from "react"
import axios from 'axios';

import { useState , useEffect} from "react";


import ContactEdit from '../Dialogs/Edit'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import PhoneIcon from '@mui/icons-material/Phone';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/BorderColor';


import Row from 'react-bootstrap/Row';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


const Domain= process.env.REACT_APP_DOMAIN

const columns = [
    { id: 'cell', label: 'Celular', minWidth: 170 },
    { id: 'name', label: 'Nombre', minWidth: 170 },
    {
      id: 'lastname', label: 'Apellido',minWidth: 170,  
    },
    {
      id: 'create',label: 'Creado',minWidth: 170,
    },
    {
      id: 'actions',label: 'Acciones',align: 'right',
    },
  ];
  
  
const rows =[];

function ClientList ()  {

    const [open, setOpen] = useState(false);
    const [disableButton, setDisable] = useState(true);

    const [selectedValue, setSelectedValue] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [contacts, setContacts] = useState({});
    const [rows ,setRows] = useState([]);
    const [phone, setPhone] = useState('');

    const [editContact, setEditContact] = useState({});



    // Table events
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const changeField = (event)=>{
      setPhone(event.target.value);
    };

    // 
    const handleClose = (value) => {
      setOpen(false);
      setSelectedValue(value);


      const formData = new FormData()
        formData.append('firstName', editContact.firstName);
        formData.append('lastName', editContact.lastName);
        formData.append('phone', editContact.phone);
        formData.append('originalPhone', phone); 


        const config = {
            headers: {
                'Content-Type': 'application/json',  
            }
        }
      try {       
        axios.put(Domain+'/api/contact',formData, config)
        .then(function(response){
          if(response.data.message=="Contact updated!"){
            setContacts({data:response.data.data});         
           }
           else{

           }

          
        })

      } catch (err) {

        console.log(err);
      }  
    };


    // actions per row events
    const editAction = (event)=>{
      console.log(event.currentTarget.id);
      setOpen(true);
      const element = document.getElementById(event.currentTarget.id);

      console.log(element)


    };

    const searchButton = (event) => {
      if (phone!=''){
        getContacts();
      }
    };

    // Search for contacts
    const getContacts =()=> {
      try {       
          axios.get(Domain+'/api/contact', { params: { phone: phone }})
          .then(function(response){

            if (response.data.message=="Success"){
              if(response.data.data!=null){
                setContacts(response.data);
                console.log(response.data)
              }else{
                // setContacts({});
 
              }

            }
          })
    } catch (err) {

      console.log(err);
    } 
  };

    useEffect(() => {

      const createRows =()=> {

        if(JSON.stringify(contacts) === '{}'){

          setEditContact({
            firstName:"",
            lastName:"",
            phone:""
          });


        }else{


          const data=contacts.data;
          setEditContact(contacts.data);

          const array =[];
  
          array.push([data.id,
          data.phone,
          data.firstName, 
          data.lastName, 
          data.createdAt,<div>
          <IconButton aria-label="edit" size="small" id={data.phone+"_edit"} disabled={disableButton} onClick={(event)=>{editAction(event)}}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton aria-label="delete" size="small"  disabled={disableButton} key={data.phone+"_delete"}>
            <DeleteIcon fontSize="small" />
          </IconButton>
          
          </div> ]);
      
          setRows(array);
          setDisable(false);

        }
       
          
      };
      createRows();
  } , [contacts,disableButton]);   


   
    return (
      <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 2, width: '200px' },
      }}
      noValidate
       mr={4}
    >
      <ContactEdit
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        data = {editContact}
      
      />
      <Grid  alignItems="center" >
        <IconButton sx={{pl: '20px', pr: '0px',ml: 2,mt: 2,mb: 2}} aria-label="menu">
          <PhoneIcon />
        </IconButton>
        <TextField
            label="Celular"
            id="outlined-size-small"
            placeholder="57310*****"
            size="small"
            onChange={(value) => {changeField(value)}}
          />
          <IconButton type="button" sx={{ p: '1px', color:'green',m:1 }} aria-label="search" onClick={() => { searchButton() }}>
            <SearchIcon />
          </IconButton>

      </Grid>
      <Row>

      </Row>
      
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}

                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id} >

                    {columns.map((column, index) => {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof row[index+1] === 'number'
                            ? column.format(row[index+1])
                            : row[index+1]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

        </Box>
    )
};

export default ClientList;

       