
import React, { Component } from 'react';

import Logo from '../images/LogoUp2.png'; 

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';


import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';


const navItems = [{name:'Home', link:'../'},
                  {name:'Enviar', link:'../'},
                  {name:'Logout', link:'../logout'},];

function My_Navbar (props) {
  const { window } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
     
    return (


        <AppBar
        position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 , background:"#01579b"}}
        >
        <Toolbar disableGutters>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, marginLeft:'5px', display: { xs: 'none', sm: 'block' } }}
          >
              <img
              src={Logo}
              width="200"
              height="70"
              className="img-responsive"

            />
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              
                item.name=="Enviar"
                ?<Button key={item.name}  sx={{ color:'white' }} ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>{item.name}</Button>
                :<Button key={item.name} sx={{ color:'white' }} href={item.link}>{item.name}</Button>
    
            ))}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <Link href='../individual' style={{ textDecoration: 'none' }}>
                       <MenuItem  >Individual</MenuItem>
                    </Link>
                    <Link href='../massive' style={{ textDecoration: 'none' }}>
                       <MenuItem  >Masivo</MenuItem>
                    </Link>                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
          </Box>
          
        </Toolbar>
      </AppBar>

      
    );

};



export default My_Navbar