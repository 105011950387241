
import React from "react"
import { useState, useEffect} from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';


const months = [{month:'Enero', value:1}, 
{month:'Febrero', value:2 },
{month:'Marzo', value:3},  
{month:'Abril', value:4},  
{month:'Mayo', value:5},  
{month: 'Junio', value:6}, 
{month: 'Julio',  value:7}, 
{month: 'Agosto',  value:8}, 
{month: 'Septiembre',  value:9}, 
{month: 'Octubre',  value:10}, 
{month: 'Noviembre',  value:11}, 
{month: 'Diciembre', value:12}]

const getDays = (year, month) => {
  return new Date(year, month, 0).getDate();
};
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

function DatePicker (props){

    const {selectedDate} = props
    const [days, setDays] = useState(getDays(new Date().getFullYear(), new Date().getMonth() +1 ));
    const [day, setDay] = useState(new Date().getDay());
    const [currentYear, setCurrentYr] = useState(new Date().getFullYear());

    const [selectedYear, setYr] = useState(new Date().getFullYear());
    const [month, setMonth] = useState( months[new Date().getMonth()].month);
    const [years, setYrs] = useState([]);
    const [daysMonth, setDaysMonth] = useState([]);



    useEffect(() => {

      const daysInSeptember = getDays(2023, 7);

      const setYears =()=> {
        setYrs(range(currentYear-10, currentYear, 1));
      }

      const setDaysOfMonth =()=> {
        setDaysMonth(range(1, days, 1));
      }
      setYears();
      setDaysOfMonth();



    });


    const handleChangeYear =(event)=> 
    {
      setDay(event.target.value)
      setYr(event.target.value);

    };

    const handleChangeMonth =(event)=> 
    {
      setMonth(event.target.value);
      setDays(getDays(selectedYear,event.target.value ))
      setDaysMonth(range(1, days, 1));
      selectedDate(selectedYear+'/' + event.target.value+'/'+ 1);
    };

    const handleChangeDay =(event)=> {
      selectedDate(selectedYear+'/' + month+'/'+ event.target.value);
      setDay( event.target.value);
    };
    return(
      <Box> 
             <FormControl sx={{ m: 1 }} variant="standard">
                <InputLabel >Año</InputLabel>
                <NativeSelect
                onChange={(event) => handleChangeYear(event)}
                >
               {
                      years.map((yr)=>{
                        return(
                          <option value={yr}>{yr}</option>
                        )
                      })
                    }
                    
               
                </NativeSelect>
            </FormControl>
            <FormControl sx={{ m: 1 }} variant="standard">
                <InputLabel >Mes</InputLabel>
                

                  <NativeSelect
                  onChange={(event) => handleChangeMonth(event)}
                  >
                    {
                      months.map((mes)=>{
                        return(
                          <option value={mes.value}>{mes.month}</option>
                        )
                      })
                    }
                    

                  </NativeSelect>


                
               
            </FormControl>
            <FormControl sx={{ m: 1 }} variant="standard">
                <InputLabel >Día</InputLabel>
                <NativeSelect
                  onChange={(event) => handleChangeDay(event)}
                  >
                 {
                      daysMonth.map((day_)=>{
                        return(
                          <option value={day_}>{day_}</option>
                        )
                      })
                    }
                    

                </NativeSelect>
            </FormControl>
    </Box>
            
    );
}

export default DatePicker;